import { createAction } from "@reduxjs/toolkit";

export const IS_MEGA_MENU_OPEN = createAction("ui/mega-menu/open");

export const SHOW_MODAL_MUTUO = createAction("ui/mutuo/selected/show");
export const HIDE_MODAL_MUTUO = createAction("ui/mutuo/selected/hide");
// export const TOGGLE_MODAL_MUTUO = createAction("ui/mutuo/selected/toggle")

export const SHOW_MODAL_COMPARE_MUTUI = createAction("ui/mutui/compare/show");
export const HIDE_MODAL_COMPARE_MUTUI = createAction("ui/mutui/compare/hide");
// export const TOGGLE_MODAL_COMPARE_MUTUI = createAction("ui/mutui/compare/toggle")

export const SHOW_MODAL_FATTIBILITA = createAction("ui/mutuo/fattibilita/show");
export const HIDE_MODAL_FATTIBILITA = createAction("ui/mutuo/fattibilita/hide");
// export const TOGGLE_MODAL_FATTIBILITA = createAction("ui/mutuo/fattibilita/toggle")

export const SHOW_MODAL_CONTATTACI = createAction("ui/mutuo/contattaci/show");
export const HIDE_MODAL_CONTATTACI = createAction("ui/mutuo/contattaci/hide");
// export const TOGGLE_MODAL_CONTATTACI = createAction("ui/mutuo/contattaci/toggle")

export const SORT_MUTUI_BY_ID = createAction("ui/mutui/sort/id");

export const SET_MUTUI_TO_SHOW = createAction("ui/mutui/to-show/set");
export const RESET_MUTUI_TO_SHOW = createAction("ui/mutui/to-show/reset");
