import { titleCase } from "../../utils/helpers";
import { labels } from "../../utils/labels";
import {
  ADD_USER_MUTUO_COMPARE,
  ADD_USER_MUTUO_HISTORY,
  CHECK_SET_USER_SELECTED_MUTUO,
  CLEAR_USER_MUTUO_COMPARE,
  REMOVE_USER_MUTUO_COMPARE,
  SET_USER_MUTUO_PARAM,
  SET_USER_PREVENTIVO_PARAM,
  SET_USER_SELECTED_MUTUO,
} from "../actions/user.actions";
import { FETCH_HISTORY } from "../thunk/history.thunk";
import { FETCH_PREVENTIVO } from "../thunk/preventivo.thunk";
import { createReducer } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

const setCookie = (name, value) => {
  const cookies = new Cookies();
  cookies.set(name, value, {
    path: "/",
    sameSite: "strict",
    expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
  });
};

export const userReducer = createReducer(
  {
    mutuo: {
      request: {},
    },
    selected: {},
    history: [],
    comparator: [],
  },
  (builder) =>
    builder
      .addCase(CHECK_SET_USER_SELECTED_MUTUO, (state, { payload }) => {
        if (payload && state.selected !== payload) {
          state.selected = { ...payload };
        }
      })
      .addCase(SET_USER_SELECTED_MUTUO, (state, { payload }) => {
        state.selected = { ...payload };
      })
      .addCase(SET_USER_MUTUO_PARAM, (state, { payload }) => {
        state.mutuo.request = { ...state.mutuo.request, ...payload };
      })
      .addCase(SET_USER_PREVENTIVO_PARAM, (state, { payload }) => {
        state.selected.ricercaApplicata = {
          ...state.selected.ricercaApplicata,
          ...payload,
        };
      })
      .addCase(ADD_USER_MUTUO_COMPARE, (state, { payload }) => {
        state.comparator = [{ ...payload }, ...state.comparator];
      })
      .addCase(REMOVE_USER_MUTUO_COMPARE, (state, { payload }) => {
        state.comparator = state.comparator.filter(
          (m) => m.CodiceMutuo !== payload.CodiceMutuo
        );
      })
      .addCase(CLEAR_USER_MUTUO_COMPARE, (state) => {
        state.comparator = [];
      })
      .addCase(ADD_USER_MUTUO_HISTORY, (state, { payload }) => {
        const mutuo = state.history.find(
          (m) => m.CodiceMutuo === payload?.CodiceMutuo
        );
        if (mutuo) {
          state.history = state.history.filter(
            (m) => m.CodiceMutuo !== payload.CodiceMutuo
          );
        }
        state.history = [payload, ...state.history];
        if (state.history?.length > 3) {
          state.history = state.history.slice(0, 3);
        }
        setCookie(
          "user-mutui-history",
          JSON.stringify(
            state.history.map((item) => [
              item.CodiceMutuo,
              Object?.values(item.ricercaApplicata),
            ])
          )
        );
      })
      .addCase(FETCH_PREVENTIVO.fulfilled, (state, action) => {
        if (action?.payload?.OutputMutui[0]) {
          state.selected = {
            ...action.payload.OutputMutui[0],
            ui: {
              stringify_params: `€ ${new Intl.NumberFormat("it-IT", {
                currency: "EUR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(action.payload.ImportoMutuo)}, Tasso ${titleCase(
                labels.tipoTasso[action.payload.TipoTasso]
              )}, ${action.payload.Durata} Anni, ${titleCase(
                labels.finalita[action.payload.Finalita]
              )}`,
            },
          };
        }
      })
      .addCase(FETCH_HISTORY.fulfilled, (state, action) => {
        if (action?.payload?.OutputMutui[0]) {
          state.history = state.history.concat({
            ...action.payload.OutputMutui[0],
            ui: {
              stringify_params: `€ ${new Intl.NumberFormat("it-IT", {
                currency: "EUR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(action.payload.ImportoMutuo)}, Tasso ${titleCase(
                labels.tipoTasso[action.payload.TipoTasso]
              )}, ${action.payload.Durata} Anni, ${titleCase(
                labels.finalita[action.payload.Finalita]
              )}`,
            },
          });
        }
      })
);
