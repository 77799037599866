import * as LoaderStyles from "./loader.module.scss";
import classNames from "classnames";
import { motion } from "framer-motion";
import React from "react";

const Loader = ({
  noWrapperMotion = false,
  fixed = false,
  noDimension = false,
}) => {
  if (noWrapperMotion) {
    return (
      <div
        className={classNames(LoaderStyles.loader, {
          [LoaderStyles.loaderFixed]: fixed,
          [LoaderStyles.noDimension]: noDimension,
        })}
      >
        <div
          className={classNames(LoaderStyles.circle, {
            [LoaderStyles.noDimension]: noDimension,
          })}
        >
          <img
            src="/logo-circle.svg"
            width="auto"
            height="auto"
            alt="logo mutuisupermarket"
          />
          <img
            src="/text-circle.svg"
            width="auto"
            height="auto"
            alt="testo rotante mutuisupermarket"
          />
        </div>
      </div>
    );
  }
  return (
    <motion.div
      className={classNames(LoaderStyles.loader, {
        [LoaderStyles.loaderFixed]: fixed,
        [LoaderStyles.noDimension]: noDimension,
      })}
      initial={{ opacity: 0, y: 0 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 0 }}
    >
      <div
        className={classNames(LoaderStyles.circle, {
          [LoaderStyles.noDimension]: noDimension,
        })}
      >
        <img
          src="/logo-circle.svg"
          width="auto"
          height="auto"
          alt="logo mutuisupermarket"
        />
        <img
          src="/text-circle.svg"
          width="auto"
          height="auto"
          alt="testo rotante mutuisupermarket"
        />
      </div>
    </motion.div>
  );
};

export default Loader;
