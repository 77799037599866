import { useTabletAndBelowMediaQuery } from "../ComponentUtils/Responsive";
import * as selectStyles from "./native-select.module.scss";
import classNames from "classnames";
import { forwardRef, useState } from "react";
import React from "react";
import Select, { components } from "react-select";

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span>Nessun risultato</span>
    </components.NoOptionsMessage>
  );
};

const reactSelectStyle = {
  option: (provided, state) => {
    return {
      ...provided,
      // backgroundColor: state.isSelected
      // 	? "#68BC6F"
      // 	: state.isFocused
      // 	? "#e0f1e1"
      // 	: "white"
      backgroundColor: state.isFocused ? "#e0f1e1" : "white",
      cursor: "pointer",
      fontFamily: "Source Sans Pro",
      //color: state.isSelected ? "white" : "black",
      color: "black",
      // "&:hover": {
      // 	backgroundColor: state.isSelected
      // 		? "#68BC6F"
      // 		: state.isFocused
      // 		? "#e0f1e1"
      // 		: "white",
      // },

      "&:hover": {
        backgroundColor: state.isFocused ? "#e0f1e1" : "white",
      },
    };
  },
  placeholder: (provided) => ({
    ...provided,
    color: "black", // Color del placeholder
    fontFamily: "Source Sans Pro",
    marginLeft: "0px",
  }),
};

const CustomReactSelect = forwardRef((props, ref) => {
  const isTabletAndBelow = useTabletAndBelowMediaQuery();
  const verificacss = props.verifica ? selectStyles.verifica : "";
  const calcolatorecss = props.calcolatore ? selectStyles.calcolatore : "";
  const notCustom = props.notCustom ? true : false;
  const errorCss = props?.selectError && selectStyles.fieldError;
  const validCss = props?.selectValid && selectStyles.fieldValid;
  const formCss = props?.form ? selectStyles.form : "";
  const valutazioneCss = props?.valutazione ? selectStyles.valutazione : "";
  const ricercatoreCss =
    props.selectCustom && !props.fromRicerca ? selectStyles.ricercatore : "";
  const preventivoCss = props.preventivo ? selectStyles.preventivo : "";
  const ricercatoreVerdeCss =
    props.fromRicerca || props.type === "BANCA"
      ? selectStyles.ricercatoreVerde
      : "";

  const combinedClassNames = classNames(
    selectStyles.nativeSelect,
    verificacss,
    calcolatorecss,
    errorCss,
    validCss,
    formCss,
    valutazioneCss,
    ricercatoreCss,
    preventivoCss,
    ricercatoreVerdeCss
  );

  const { onChange, ...restProps } = props;
  const [currentValue, setCurrentValue] = useState(props.value);

  //MOBILE - NOT CUSTOM  E CUSTOM
  if (isTabletAndBelow) {
    if (notCustom) {
      return (
        <Select
          ref={ref}
          components={{
            NoOptionsMessage,
            ...props.components,
          }}
          loadingMessage={() => "Caricamento..."}
          placeholder=""
          isClearable={false}
          tabSelectsValue={false}
          autoFocus={false}
          isSearchable={false}
          openMenuOnFocus={true}
          styles={reactSelectStyle}
          defaultValue={currentValue}
          {...props}
        />
      );
    }
    return (
      <select
        ref={ref}
        onFocus={(e) => {
          if (props.onFocus) {
            props?.onFocus(e);
          }
        }}
        onBlur={(e) => {
          if (props.onBlur) {
            props?.onBlur(e);
          }
        }}
        onChange={(e) => {
          const selectedOption = props.options.find(
            (option) => String(option.value) === e.target.value
          );
          props.onChange(selectedOption);
          setCurrentValue(selectedOption);
        }}
        value={currentValue?.value}
        className={combinedClassNames}
      >
        {props.emptyValue && <option value=""></option>}
        {props.addDisableOption && (
          <option value="" disabled selected>
            {props.addDisableOption}
          </option>
        )}
        {props.name === "TipoTasso" && props.options.length === 0 && (
          <option value="" disabled selected>
            Fisso
          </option>
        )}
        {props.name === "Finalita" && props.options.length === 0 && (
          <option value="" disabled selected>
            Acquisto prima casa
          </option>
        )}
        {props.options.map((option) => (
          <option key={String(option.value)} value={String(option.value)}>
            {option.label}
          </option>
        ))}
      </select>
    );
  }

  //DESKTOP
  return (
    <Select
      ref={ref}
      components={{
        NoOptionsMessage,
        ...props.components,
      }}
      defaultValue={currentValue}
      loadingMessage={() => "Caricamento..."}
      placeholder={
        props.inputId === "ClasseEnergetica"
          ? props.defaultValue?.label || "Non lo so"
          : props.inputId === "Finalita" &&
            (props.type === "HP" || props.type === "APC")
          ? props.value?.label || "Acquisto prima casa"
          : props.inputId === "Finalita" && props.type === "SUR"
          ? props.value?.label || "Surroga"
          : props.inputId === "TipoTasso"
          ? props.value?.label || "Fisso"
          : props.calcolatore || props.valutazione
          ? props.defaultValue?.label || props.value?.label
          : props.value?.label || ""
      }
      isClearable={false}
      tabSelectsValue={false}
      autoFocus={false}
      isSearchable={false}
      openMenuOnFocus={true}
      styles={reactSelectStyle}
      onChange={(selectedOption) => {
        props.onChange(selectedOption);
        setCurrentValue(selectedOption);
      }}
      {...restProps}
    />
  );
});

CustomReactSelect.displayName = "CustomReactSelect";

export default CustomReactSelect;
