import { createAction } from "@reduxjs/toolkit";

export const CHAT_MUTUO_SHOW = createAction("chatmutuo/show");
export const CHAT_MUTUO_HIDE = createAction("chatmutuo/hide");
export const CHAT_MUTUO_TOGGLE = createAction("chatmutuo/toggle");

export const CHAT_MUTUO_IS_WIZARD_VISIBLE = createAction(
  "chatmutuo/wizard/isvisible"
);
export const CHAT_MUTUO_SHOW_WIZARD = createAction("chatmutuo/wizard/show");
export const CHAT_MUTUO_HIDE_WIZARD = createAction("chatmutuo/wizard/hide");
export const CHAT_MUTUO_TOGGLE_WIZARD = createAction("chatmutuo/wizard/toggle");

export const CHAT_MUTUO_SHOW_CHAT = createAction("chatmutuo/chat/show");
export const CHAT_MUTUO_HIDE_CHAT = createAction("chatmutuo/chat/hide");
export const CHAT_MUTUO_TOGGLE_CHAT = createAction("chatmutuo/chat/toggle");

export const CHAT_MUTUO_NEXT_WIZARD_STEP = createAction(
  "chatmutuo/wizard/step/next"
);

export const CHAT_MUTUO_ADD_MESSAGES = createAction("chatmutuo/message/add");

export const CHAT_MUTUO_FETCH_MESSAGE_LOADING_TRUE = createAction(
  "chatmutuo/chat/message/fetch/loading/true"
);
export const CHAT_MUTUO_FETCH_MESSAGE_LOADING_FALSE = createAction(
  "chatmutuo/chat/message/fetch/loading/false"
);

export const CHAT_MUTUO_SET_MESSAGES_UID = createAction(
  "chatmutuo/chat/uid/set"
);

export const CHAT_MUTUO_SET_MESSAGES_CLIENT_ID = createAction(
  "chatmutuo/chat/clientid/set"
);

export const CHAT_MUTUO_SET_CONVERSATION_TYPE = createAction(
  "chatmutuo/conversation/type/set"
);
export const CHAT_MUTUO_HIDE_OLD_QUESTIONS = createAction(
  "chatmutuo/chat/questions/hide"
);
export const CHAT_MUTUO_SHOW_CURRENT_QUESTIONS = createAction(
  "chatmutuo/chat/questions/show"
);
export const CHAT_MUTUO_RICERCA_MUTUI = createAction(
  "chatmutuo/chat/ricerca/true"
);
export const CHAT_MUTUO_NOT_RICERCA_MUTUI = createAction(
  "chatmutuo/chat/ricerca/false"
);

export const CHAT_MUTUO_IS_LAST_LINK_MESSAGES_VISIBILE = createAction(
  "chatmutuo/message/lastlink/add"
);

export const CHAT_MUTUO_LATEST_CMS_MESSAGE = createAction(
  "chatmutuo/message/latestcms/add"
);

export const CHAT_MUTUO_IS_ACTIVE_LAST_CMS_MESSAGE = createAction(
  "chatmutuo/message/latestcms/active"
);
