import { MSMRequest } from "../../api/msm";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const FETCH_MUTUI_BY_ID = createAsyncThunk(
  "requests/researcher/fetch",
  async (args, thunkAPI) => {
    try {
      const response = await MSMRequest(args.endpoint, args.postData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
