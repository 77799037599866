import { createSelector } from "@reduxjs/toolkit";

export const getUtmChatbot = createSelector(
  (state) => state.config.settings.utmchatbot,
  (state) => state
);

export const getGclid = createSelector(
  (state) => state.config.settings.gclid,
  (state) => state
);

export const getMsmlid = createSelector(
  (state) => state.config.settings.msmlid,
  (state) => state
);

export const getSessionId = createSelector(
  (state) => state.config.settings.sessionFeId,
  (state) => state
);

export const getQueryStringValues = createSelector(
  (state) => state.config.settings.queryStringValues,
  (state) => state
);

export const getLastMethodLid = createSelector(
  (state) => state.config.settings.lastMethodLid,
  (state) => state
);

export const getUtmSource = createSelector(
  (state) => state.config.settings.utmSource,
  (state) => state
);

export const getUtmCampaign = createSelector(
  (state) => state.config.settings.utmCampaign,
  (state) => state
);

export const getUtmMedium = createSelector(
  (state) => state.config.settings.utmMedium,
  (state) => state
);

export const getMsclkid = createSelector(
  (state) => state.config.settings.msclkid,
  (state) => state
);
