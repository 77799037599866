import { createAction } from "@reduxjs/toolkit";

export const CLICK_COUNTER_CHATMUTUO = createAction("config/add/clickCounter");

export const CONFIG_ADD_UTMCHATBOT = createAction("config/add/utmchatbot");

export const CONFIG_ADD_GCLID = createAction("config/add/gclid");

export const CONFIG_ADD_MSMLID = createAction("config/add/msmlid");

export const CONFIG_ADD_SESSION_ID = createAction("config/add/SessionFeId");

export const CONFIG_ADD_QUERY_STRING_VALUES = createAction(
  "config/add/QueryStringValues"
);

export const CONFIG_ADD_LAST_METHOD_LID = createAction(
  "config/add/lastMethodLid"
);

export const CONFIG_ADD_UTMSOURCE = createAction("config/add/utmSource");

export const CONFIG_ADD_UTMCAMPAIGN = createAction("config/add/utmCampaign");

export const CONFIG_ADD_UTMMEDIUM = createAction("config/add/utmMedium");

export const CONFIG_ADD_MSCLKID = createAction("config/add/msclkid");
