import { MSMRequest } from "../../../../api/msm";
import Text from "../../../Text";
import * as MarketingAffiliationStyles from "./marketing-affiliation.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import parse from "html-react-parser";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Controller, useForm } from "react-hook-form";
import { IMaskInput } from "react-imask";
import * as yup from "yup";
import "yup-phone-lite";

const schemaMarketingAffiliation = yup.object().shape({
  Nome: yup
    .string()
    .min(2, 'Il campo "Nome" deve contenere almeno 2 caratteri')
    .trim()
    .required('Il campo "Nome" è obbligatorio')
    .matches(/^[aA-zZ\s]+$/, 'Il campo "Nome" può contenere solo lettere'),
  Cognome: yup
    .string()
    .min(2, 'Il campo "Cognome" deve contenere almeno 2 caratteri')
    .trim()
    .required('Il campo "Cognome" è obbligatorio')
    .matches(/^[aA-zZ\s]+$/, 'Il campo "Cognome" può contenere solo lettere'),
  Sito: yup
    .string()
    .trim()
    .required('Il campo "Sito" è obbligatorio')
    .url("Il campo 'Sito' deve essere un URL valido"),
  Telefono: yup
    .string()
    .phone("IT", 'Il campo "Telefono" deve essere un numero valido')
    .required('Il campo "Telefono" è obbligatorio'),
  Posizione: yup.string().trim().nullable().optional(),
  Email: yup.string().email().nullable().optional(),
  Note: yup.string().nullable().optional(),
  AccettaTrattamentoDati: yup
    .boolean()
    .oneOf([true], "Devi accettare la privacy policy per inviare il modulo"),
});

function MarketingAffiliation({ labelCta, consenso, setSettingsModal }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schemaMarketingAffiliation),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      Nome: "",
      Cognome: "",
      Sito: "",
      Telefono: "",
      Posizione: "",
      Email: "",
      Note: "",
      AccettaTrattamentoDati: false,
    },
  });

  const watchs = watch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (data) => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("formMarketingAffiliation");
    const response = await MSMRequest("ContattoMarketingAffiliation", {
      ...data,
      ...{ gRecaptchaResponse: token },
    });
    if (response.success) {
      const BodyContent = (
        <Text content="Il messaggio è stato inviato con successo. A breve sarai ricontattato." />
      );
      setSettingsModal({
        title: "Grazie",
        content: BodyContent,
      });
      reset();
    } else {
      const BodyContent = (
        <Text content="Si è verificato un errore. Riprova più tardi." />
      );
      setSettingsModal({
        title: "Attenzione",
        content: BodyContent,
      });
    }
  };

  const onError = (errors, e) => {
    const BodyContent = (
      <>
        {Object.keys(errors).map((key, index) => {
          return <p key={index}>{errors[key].message}</p>;
        })}
      </>
    );
    setSettingsModal({
      title: "Attenzione",
      content: BodyContent,
    });
  };

  return (
    <Form className={MarketingAffiliationStyles.form} autoComplete="off">
      <Row className={MarketingAffiliationStyles.formRow}>
        <Col md={4}>
          <div
            className={classNames(MarketingAffiliationStyles.field, {
              [MarketingAffiliationStyles.fieldError]: errors.Nome,
              [MarketingAffiliationStyles.fieldValid]:
                !errors.Nome && watchs.Nome?.length > 0,
            })}
          >
            <label
              htmlFor="Nome"
              className={classNames({
                [MarketingAffiliationStyles.filled]: watchs.Nome?.length > 0,
              })}
            >
              Nome*
            </label>
            <input type="text" {...register("Nome")} />
          </div>
        </Col>
        <Col md={4}>
          <div
            className={classNames(MarketingAffiliationStyles.field, {
              [MarketingAffiliationStyles.fieldError]: errors.Cognome,
              [MarketingAffiliationStyles.fieldValid]:
                !errors.Cognome && watchs.Cognome?.length > 0,
            })}
          >
            <label
              htmlFor="Cognome"
              className={classNames({
                [MarketingAffiliationStyles.filled]: watchs.Cognome?.length > 0,
              })}
            >
              Cognome*
            </label>
            <input type="text" {...register("Cognome")} />
          </div>
        </Col>
        <Col md={4}>
          <div
            className={classNames(MarketingAffiliationStyles.field, {
              [MarketingAffiliationStyles.fieldError]: errors.Sito,
              [MarketingAffiliationStyles.fieldValid]:
                !errors.Sito && watchs.Sito?.length > 0,
            })}
          >
            <label
              htmlFor="Sito"
              className={classNames({
                [MarketingAffiliationStyles.filled]: watchs.Sito?.length > 0,
              })}
            >
              Sito*
            </label>
            <input type="text" {...register("Sito")} />
          </div>
        </Col>
        <Col md={4}>
          <div
            className={classNames(MarketingAffiliationStyles.field, {
              [MarketingAffiliationStyles.fieldError]: errors.Telefono,
              [MarketingAffiliationStyles.fieldValid]:
                !errors.Telefono && watchs.Telefono?.length > 0,
            })}
          >
            <label
              htmlFor="Telefono"
              className={classNames({
                [MarketingAffiliationStyles.filled]:
                  watchs.Telefono?.length > 0,
              })}
            >
              Telefono*
            </label>
            <Controller
              name="Telefono"
              control={control}
              valueAsNumber={true}
              render={({ field }) => (
                <IMaskInput
                  mask={Number}
                  unmask={true}
                  onAccept={(value, mask) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className={MarketingAffiliationStyles.field}>
            {" "}
            <label
              htmlFor="Posizione"
              className={classNames({
                [MarketingAffiliationStyles.filled]:
                  watchs.Posizione?.length > 0,
              })}
            >
              Posizione
            </label>
            <input type="text" {...register("Posizione")} />
          </div>
        </Col>
        <Col md={4}>
          <div
            className={classNames(MarketingAffiliationStyles.field, {
              [MarketingAffiliationStyles.fieldError]: errors.Email,
              [MarketingAffiliationStyles.fieldValid]:
                !errors.Email && watchs.Email?.length > 0,
            })}
          >
            <label
              htmlFor="Email"
              className={classNames({
                [MarketingAffiliationStyles.filled]: watchs.Email?.length > 0,
              })}
            >
              E-mail
            </label>
            <input type="email" {...register("Email")} />
          </div>
        </Col>
        <Col md={12}>
          <div className={MarketingAffiliationStyles.field}>
            <textarea {...register("Note")} placeholder="Messaggio" />
          </div>
        </Col>
        <Col md={12}>
          <div
            className={classNames(MarketingAffiliationStyles.fieldCheckbox, {
              [MarketingAffiliationStyles.fieldError]:
                errors?.AccettaTrattamentoDati,
            })}
          >
            <input type="checkbox" {...register("AccettaTrattamentoDati")} />
            <label
              htmlFor="AccettaTrattamentoDati"
              className={MarketingAffiliationStyles.smallText}
            >
              {parse(consenso.label, {
                replace: (domNode) => {
                  if (
                    domNode.attribs &&
                    domNode.attribs["data-config"] &&
                    domNode.attribs["data-config"].length > 0
                  ) {
                    const dataConfigParsed = JSON.parse(
                      domNode.attribs["data-config"]
                    );
                    switch (dataConfigParsed.type) {
                      case "open-popup":
                        return (
                          <button
                            className={MarketingAffiliationStyles.trigger}
                            onClick={(e) => {
                              e.preventDefault();
                              const BodyContent = (
                                <>
                                  <Text
                                    content={consenso.popup.testo}
                                    removeP={false}
                                  />
                                </>
                              );
                              setSettingsModal({
                                title: consenso.popup.titolo,
                                content: BodyContent,
                              });
                            }}
                          >
                            {dataConfigParsed.label}
                          </button>
                        );
                      default:
                        break;
                    }
                  }
                },
              })}
            </label>
          </div>
        </Col>
        <Col md={12}>
          <button
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
            className={MarketingAffiliationStyles.button}
            disabled={isSubmitting}
          >
            {labelCta}
          </button>
        </Col>
      </Row>
    </Form>
  );
}

export default MarketingAffiliation;
