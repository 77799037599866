import {
  CHAT_MUTUO_ADD_MESSAGES,
  CHAT_MUTUO_FETCH_MESSAGE_LOADING_FALSE,
  CHAT_MUTUO_FETCH_MESSAGE_LOADING_TRUE,
  CHAT_MUTUO_HIDE,
  CHAT_MUTUO_HIDE_CHAT,
  CHAT_MUTUO_HIDE_OLD_QUESTIONS,
  CHAT_MUTUO_HIDE_WIZARD,
  CHAT_MUTUO_IS_WIZARD_VISIBLE,
  CHAT_MUTUO_NEXT_WIZARD_STEP,
  CHAT_MUTUO_NOT_RICERCA_MUTUI,
  CHAT_MUTUO_RICERCA_MUTUI,
  CHAT_MUTUO_SET_CONVERSATION_TYPE,
  CHAT_MUTUO_SET_MESSAGES_CLIENT_ID,
  CHAT_MUTUO_SET_MESSAGES_UID,
  CHAT_MUTUO_SHOW,
  CHAT_MUTUO_SHOW_CHAT,
  CHAT_MUTUO_SHOW_CURRENT_QUESTIONS,
  CHAT_MUTUO_SHOW_WIZARD,
  CHAT_MUTUO_TOGGLE,
  CHAT_MUTUO_TOGGLE_CHAT,
  CHAT_MUTUO_TOGGLE_WIZARD,
  CHAT_MUTUO_IS_LAST_LINK_MESSAGES_VISIBILE,
  CHAT_MUTUO_LATEST_CMS_MESSAGE,
  CHAT_MUTUO_IS_ACTIVE_LAST_CMS_MESSAGE,
} from "../actions/chatmutuo.actions";
import {
  CHAT_MUTUO_FETCH_HISTORY,
  CHAT_MUTUO_FETCH_MESSAGE,
} from "../thunk/chatmutuo.thunk";
import { createReducer } from "@reduxjs/toolkit";
import { last } from "lodash";
import Cookies from "universal-cookie";

export const chatMutuoReducer = createReducer(
  {
    show: false,
    showWizard: true,
    showChat: false,
    wizardStep: 0,
    messages: {
      uid: "",
      clientId: "",
      error: false,
      loading: false,
      loadingHistory: false,
      conversationType: "PROFESSIONALE",
      data: [],
    },
    numeroRisposta: 0,
    maxRisposte: 0,
    hideQuestions: false,
    mutuiFromChat: false,
    isLastVisibile: false,
    lastCmsMessage: [],
    isActiveLastCmsMessage: true,
  },
  (builder) =>
    builder
      .addCase(CHAT_MUTUO_SET_CONVERSATION_TYPE, (state, { payload }) => {
        state.messages.conversationType = payload;
      })
      .addCase(CHAT_MUTUO_SHOW, (state) => {
        state.show = true;
      })
      .addCase(CHAT_MUTUO_HIDE, (state) => {
        state.show = false;
      })
      .addCase(CHAT_MUTUO_TOGGLE, (state) => {
        state.show = !state.show;
      })
      .addCase(CHAT_MUTUO_IS_WIZARD_VISIBLE, (state, { payload }) => {
        state.showWizard = payload;
        state.showChat = !payload;
      })
      .addCase(CHAT_MUTUO_SHOW_WIZARD, (state) => {
        state.showWizard = true;
      })
      .addCase(CHAT_MUTUO_HIDE_WIZARD, (state) => {
        state.showWizard = false;
      })
      .addCase(CHAT_MUTUO_TOGGLE_WIZARD, (state) => {
        state.showWizard = !state.showWizard;
      })
      .addCase(CHAT_MUTUO_SHOW_CHAT, (state) => {
        state.showChat = true;
      })
      .addCase(CHAT_MUTUO_HIDE_CHAT, (state) => {
        state.showChat = false;
      })
      .addCase(CHAT_MUTUO_TOGGLE_CHAT, (state) => {
        state.showChat = !state.showChat;
      })
      .addCase(CHAT_MUTUO_NEXT_WIZARD_STEP, (state) => {
        state.wizardStep++;
      })
      .addCase(CHAT_MUTUO_ADD_MESSAGES, (state, { payload }) => {
        const index =
          state.messages.data.findIndex(
            (message) => message.id === payload.id
          ) || -1;
        if (index > -1) {
          state.messages.data[index] = {
            ...state.messages.data[index],
            ...payload,
          };
        } else {
          state.messages.data = [...state.messages.data, payload];
        }
      })
      .addCase(CHAT_MUTUO_FETCH_MESSAGE.fulfilled, (state, { payload }) => {
        //  console.log("payload di message fulfilled: ", payload)
        state.messages.uid = payload.incomingUID;
        state.messages.loading = false;
        state.numeroRisposta = payload?.nRisposta ?? 404;
        state.maxRisposte = payload?.mRisposte ?? 404;
        const index =
          state.messages.data.findIndex(
            (message) => message.id === payload.id
          ) || -1;
        if (index > -1) {
          state.messages.data[index] = {
            ...(state.messages.data[index] = {
              ...state.messages.data[index],
              Messaggio: payload.completedMessage,
            }),
            loading: false,
          };
        }
        new Cookies().set("chatmutuo-uid", payload.incomingUID, {
          path: "/",
        });
      })
      .addCase(CHAT_MUTUO_FETCH_MESSAGE.pending, (state) => {
        state.messages.loading = true;
      })
      .addCase(CHAT_MUTUO_FETCH_MESSAGE.rejected, (state) => {
        const index =
          state.messages.data.findIndex(
            (message) => message.loading === true
          ) || -1;
        if (index > -1) {
          // console.log("ERROR INDEX -1 REDUX")
          state.messages.data[index] = {
            ...state.messages.data[index],
            loading: false,
            error: true,
            Messaggio: state.messages.data[index],
          };
        } else {
          state.messages.data.push({
            id: state?.messages?.id || `error-${Date.now()}`,
            Messaggio: "Ops! Qualcosa è andato storto. Riprova più tardi.",
            loading: false,
            error: true,
          });
        }
        state.messages.error = true;
        state.messages.loading = false;
      })
      .addCase(CHAT_MUTUO_FETCH_MESSAGE_LOADING_TRUE, (state) => {
        state.messages.loading = true;
      })
      .addCase(CHAT_MUTUO_FETCH_MESSAGE_LOADING_FALSE, (state) => {
        state.messages.loading = false;
      })
      .addCase(CHAT_MUTUO_SET_MESSAGES_UID, (state, { payload }) => {
        state.messages.uid = payload;
      })
      .addCase(CHAT_MUTUO_SET_MESSAGES_CLIENT_ID, (state, { payload }) => {
        state.messages.clientId = payload;
      })
      .addCase(CHAT_MUTUO_FETCH_HISTORY.pending, (state) => {
        state.messages.loadingHistory = true;
      })
      .addCase(CHAT_MUTUO_FETCH_HISTORY.fulfilled, (state, { payload }) => {
        if (payload?.success && payload?.data?.length > 0) {
          state.messages.data = [
            ...(payload?.data || []),
            ...state.messages.data,
          ];
          state.showWizard = false;
          state.showChat = true;
          // console.log("THIS IS PAYLOAD fetchhistory:")
          // 	console.log(payload.data)
          // 	console.log("BUT THIS IS STATE")
          // 	console.log(state.messages.data)
          const lastAssistantMessage = payload.data
            .slice()
            .reverse()
            .find((message) => message.sender === "assistant");

          if (lastAssistantMessage) {
            state.numeroRisposta = lastAssistantMessage.numeroRisposta;
            state.maxRisposte = lastAssistantMessage.maxRisposte;
            // console.log("THIS IS PAYLOAD lastassistant:")
            // console.log(lastAssistantMessage.numeroRisposta)
            // console.log(lastAssistantMessage.maxRisposte)
          }
        }
        state.messages.loadingHistory = false;
      })
      .addCase(CHAT_MUTUO_FETCH_HISTORY.rejected, (state) => {
        state.messages.loadingHistory = false;
      })
      .addCase(CHAT_MUTUO_HIDE_OLD_QUESTIONS, (state) => {
        state.hideQuestions = true;
      })
      .addCase(CHAT_MUTUO_SHOW_CURRENT_QUESTIONS, (state) => {
        state.hideQuestions = false;
      })
      .addCase(CHAT_MUTUO_RICERCA_MUTUI, (state) => {
        state.mutuiFromChat = true;
      })
      .addCase(CHAT_MUTUO_NOT_RICERCA_MUTUI, (state) => {
        state.mutuiFromChat = false;
      })
      .addCase(
        CHAT_MUTUO_IS_LAST_LINK_MESSAGES_VISIBILE,
        (state, { payload }) => {
          state.isLastVisibile = payload;
        }
      )
      .addCase(CHAT_MUTUO_LATEST_CMS_MESSAGE, (state, { payload }) => {
        state.lastCmsMessage = payload;
      })
      .addCase(CHAT_MUTUO_IS_ACTIVE_LAST_CMS_MESSAGE, (state, { payload }) => {
        state.isActiveLastCmsMessage = payload;
      })
);
