import { middlewareComparator } from "./middlewares/comparator.middleware";
import { chatMutuoReducer } from "./reducers/chatmutuo.reducer";
import { configReducer } from "./reducers/config.reducer";
import { requestsReducer } from "./reducers/requests.reducer";
import { uiReducer } from "./reducers/ui.reducer";
import { userReducer } from "./reducers/user.reducer";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  user: userReducer,
  requests: requestsReducer,
  ui: uiReducer,
  config: configReducer,
  chatmutuo: chatMutuoReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(middlewareComparator),
});

export default store;
