import { createSelector } from "@reduxjs/toolkit";

export const getUserSelectedMutuo = createSelector(
  (state) => state.user.selected,
  (state) => state
);

export const getUserMutuoRequest = createSelector(
  (state) => state.user.mutuo.request,
  (state) => state
);

export const getUserHistory = createSelector(
  (state) => ({
    history_enabled: state.user.history.length > 0,
    history_list: state.user.history || [],
  }),
  (state) => state
);

export const getComparatorCheck = createSelector(
  (state) => state.user.comparator,
  (_, CodiceMutuo) => CodiceMutuo,
  (comparator, CodiceMutuo) =>
    comparator.filter((m) => m.CodiceMutuo === CodiceMutuo).length > 0
);

export const getUserComparatorMutui = createSelector(
  (state) => state.user.comparator || [],
  (state) => state
);
