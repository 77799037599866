import ModalService from "../../ModalService";
import Text from "../../Text";
import { enableAnchorOnComponents } from "../anchorFlexible";
import { marginBottomComponent } from "../marginBottomFlexible";
import BancaPartner from "./BancaPartner";
import ConsulenzaGratuita from "./ConsulenzaGratuita";
import ContattiStampaMedia from "./ContattiStampaMedia";
import LavoraConNoi from "./LavoraConNoi";
import MarketingAffiliation from "./MarketingAffiliation";
import * as FormsStyles from "./forms.module.scss";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function Forms({ layoutData, noContainer = false, useOwnWrapper = false }) {
  const [settingsModal, setSettingsModal] = useState({});
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (settingsModal && Object.keys(settingsModal).length > 0) {
      setModalTitle(settingsModal.title);
      setModalContent(settingsModal.content);
      setShowModal(true);
    }
  }, [settingsModal]);
  const { titolo, sottotitolo, form, labelCta, consenso } = layoutData;
  const anchor = enableAnchorOnComponents(layoutData);
  const marginBottom = marginBottomComponent(layoutData);
  if (useOwnWrapper) {
    return (
      <section className={marginBottom} {...anchor}>
        <div className={FormsStyles.wrapper}>
          {titolo?.length > 0 && (
            <h3 className={FormsStyles.title}>{titolo}</h3>
          )}
          {sottotitolo?.length > 0 && (
            <Text
              content={sottotitolo}
              removeP={false}
              className={FormsStyles.subtitle}
            />
          )}
          {form === "marketing_affiliation" && (
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
              language="it"
            >
              <MarketingAffiliation
                labelCta={labelCta}
                consenso={consenso}
                setSettingsModal={setSettingsModal}
              />
            </GoogleReCaptchaProvider>
          )}
          {form === "stampa_media" && (
            <ContattiStampaMedia
              labelCta={labelCta}
              consenso={consenso}
              setSettingsModal={setSettingsModal}
            />
          )}
          {form === "banca_partner" && (
            <BancaPartner
              labelCta={labelCta}
              consenso={consenso}
              setSettingsModal={setSettingsModal}
            />
          )}
          {form === "consulenza_gratuita" && (
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
              language="it"
            >
              <ConsulenzaGratuita
                labelCta={labelCta}
                consenso={consenso}
                setSettingsModal={setSettingsModal}
              />
            </GoogleReCaptchaProvider>
          )}
          {form === "lavora_con_noi" && (
            <LavoraConNoi
              labelCta={labelCta}
              consenso={consenso}
              setSettingsModal={setSettingsModal}
            />
          )}
        </div>
        {/* //// */}
        <ModalService
          title={modalTitle}
          show={showModal}
          setShow={setShowModal}
          children={modalContent}
        />
        {/* //// */}
      </section>
    );
  }
  if (noContainer) {
    return (
      <>
        {titolo?.length > 0 && <h3 className={FormsStyles.title}>{titolo}</h3>}
        {sottotitolo?.length > 0 && (
          <Text
            content={sottotitolo}
            removeP={false}
            className={FormsStyles.subtitle}
          />
        )}
        {form === "marketing_affiliation" && (
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
            language="it"
          >
            <MarketingAffiliation
              labelCta={labelCta}
              consenso={consenso}
              setSettingsModal={setSettingsModal}
            />
          </GoogleReCaptchaProvider>
        )}
        {form === "stampa_media" && (
          <ContattiStampaMedia
            labelCta={labelCta}
            consenso={consenso}
            setSettingsModal={setSettingsModal}
          />
        )}
        {form === "banca_partner" && (
          <BancaPartner
            labelCta={labelCta}
            consenso={consenso}
            setSettingsModal={setSettingsModal}
          />
        )}
        {form === "consulenza_gratuita" && (
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
            language="it"
          >
            <ConsulenzaGratuita
              labelCta={labelCta}
              consenso={consenso}
              setSettingsModal={setSettingsModal}
            />
          </GoogleReCaptchaProvider>
        )}
        {form === "lavora_con_noi" && (
          <LavoraConNoi
            labelCta={labelCta}
            consenso={consenso}
            setSettingsModal={setSettingsModal}
          />
        )}
        {/* //// */}
        <ModalService
          title={modalTitle}
          show={showModal}
          setShow={setShowModal}
          children={modalContent}
        />
        {/* //// */}
      </>
    );
  }
  return (
    <section className={marginBottom} {...anchor}>
      <Container>
        <div className={FormsStyles.wrapper}>
          {titolo?.length > 0 && (
            <h3 className={FormsStyles.title}>{titolo}</h3>
          )}
          {sottotitolo?.length > 0 && (
            <Text
              content={sottotitolo}
              removeP={false}
              className={FormsStyles.subtitle}
            />
          )}
          {form === "marketing_affiliation" && (
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
              language="it"
            >
              <MarketingAffiliation
                labelCta={labelCta}
                consenso={consenso}
                setSettingsModal={setSettingsModal}
              />
            </GoogleReCaptchaProvider>
          )}
          {form === "stampa_media" && (
            <ContattiStampaMedia
              labelCta={labelCta}
              consenso={consenso}
              setSettingsModal={setSettingsModal}
            />
          )}
          {form === "banca_partner" && (
            <BancaPartner
              labelCta={labelCta}
              consenso={consenso}
              setSettingsModal={setSettingsModal}
            />
          )}
          {form === "consulenza_gratuita" && (
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
              language="it"
            >
              <ConsulenzaGratuita
                labelCta={labelCta}
                consenso={consenso}
                setSettingsModal={setSettingsModal}
              />
            </GoogleReCaptchaProvider>
          )}
          {form === "lavora_con_noi" && (
            <LavoraConNoi
              labelCta={labelCta}
              consenso={consenso}
              setSettingsModal={setSettingsModal}
            />
          )}
        </div>
      </Container>
      {/* //// */}
      <ModalService
        title={modalTitle}
        show={showModal}
        setShow={setShowModal}
        children={modalContent}
      />
      {/* //// */}
    </section>
  );
}

export default Forms;
