import Footer from "./Footer";
import MenuDesktop from "./MenuDesktop";
import ServiceBar from "./ServiceBar";
import loadable from "@loadable/component";
import classNames from "classnames";
// import { AnimatePresence, motion } from "framer-motion"
import React, { useEffect } from "react";

const ConfrontoModalMutuo = loadable(() => import("./ConfrontoModalMutuo"));
// const ScrollToTop = loadable(() => import("./ScrollToTop"))
const ChatMutuo = loadable(() => import("./ChatMutuo"));

// const defaultLayoutVariants = {
// 	initial: { opacity: 0 },
// 	animate: { opacity: 1 },
// 	exit: { opacity: 0 },
// 	duration: 0.2,
// }
// const enterToRightLayoutVariants = {
// 	initial: { opacity: 0, x: 100 },
// 	animate: { opacity: 1, x: 0 },
// 	exit: { opacity: 0, x: -100 },
// 	duration: 0.2,
// }

const Layout = ({ children, data, location }) => {
  const isHpTemplate = data?.page?.template?.hp_template || false;
  const isHpTemplateLightVersion =
    data?.page?.template?.hp_template?.versioneLight || false;
  const isFullLayout = location?.state?.full || false;
  const isChatMutuoTemplate =
    data?.page?.template?.templateName === "ChatMutuo" || false;
  const isPageConfermaAppuntamenti =
    data?.page?.template?.templateName === "Conferma Appuntamenti" || false;

  // const animationFramerMotion = isHpTemplate
  // 	? {}
  // 	: isFullLayout
  // 	? enterToRightLayoutVariants
  // 	: defaultLayoutVariants

  return (
    <>
      {!isFullLayout && (
        <MenuDesktop
          isHpTemplate={isHpTemplate}
          isChatMutuoTemplate={isChatMutuoTemplate}
          ligthVersion={
            isHpTemplateLightVersion || isPageConfermaAppuntamenti || false
          }
        />
      )}
      {/* <AnimatePresence mode="wait"> */}
      <main
        // key={location.pathname}
        className={classNames({
          main: !isFullLayout,
          "main-no-margin": isHpTemplate || isChatMutuoTemplate,
          "main-no-overflow chatmutuo": isChatMutuoTemplate,
        })}
        // {...animationFramerMotion}
      >
        {children}
      </main>
      {/* </AnimatePresence> */}
      {!isFullLayout && (
        <>
          <Footer />
          {!isPageConfermaAppuntamenti && (
            <ServiceBar
              isChatMutuoTemplate={isChatMutuoTemplate}
              isHpTemplateLightVersion={isHpTemplateLightVersion}
            />
          )}
          <ConfrontoModalMutuo />
          {/* <ScrollToTop /> */}
          {!isChatMutuoTemplate && !isPageConfermaAppuntamenti && (
            <ChatMutuo location={location} />
          )}
        </>
      )}
    </>
  );
};

export default Layout;
