import {
  CONFIG_ADD_GCLID,
  CONFIG_ADD_MSMLID,
  CONFIG_ADD_QUERY_STRING_VALUES,
  CONFIG_ADD_SESSION_ID,
  CONFIG_ADD_UTMCHATBOT,
  CONFIG_ADD_LAST_METHOD_LID,
  CONFIG_ADD_UTMSOURCE,
  CONFIG_ADD_UTMCAMPAIGN,
  CONFIG_ADD_UTMMEDIUM,
  CONFIG_ADD_MSCLKID,
} from "../actions/config.actions";
import { createReducer } from "@reduxjs/toolkit";
import { add } from "lodash";

export const configReducer = createReducer(
  {
    settings: {
      utmchatbot: null,
      msmlid: null,
      gclid: null,
      sessionFeId: null,
      utmSource: null,
      utmCampaign: null,
      utmMedium: null,
      lastMethodLid: null,
      msclkid: null,
      queryStringValues: {},
    },
  },
  (builder) =>
    builder
      .addCase(CONFIG_ADD_UTMCHATBOT, (state, action) => {
        state.settings.utmchatbot = action.payload;
      })
      .addCase(CONFIG_ADD_MSMLID, (state, action) => {
        state.settings.msmlid = action.payload;
      })
      .addCase(CONFIG_ADD_GCLID, (state, action) => {
        state.settings.gclid = action.payload;
      })
      .addCase(CONFIG_ADD_SESSION_ID, (state, action) => {
        state.settings.sessionFeId = action.payload;
      })
      .addCase(CONFIG_ADD_QUERY_STRING_VALUES, (state, action) => {
        state.settings.queryStringValues = action.payload;
      })
      .addCase(CONFIG_ADD_LAST_METHOD_LID, (state, action) => {
        state.settings.lastMethodLid = action.payload;
      })
      .addCase(CONFIG_ADD_UTMSOURCE, (state, action) => {
        state.settings.utmSource = action.payload;
      })
      .addCase(CONFIG_ADD_UTMCAMPAIGN, (state, action) => {
        state.settings.utmCampaign = action.payload;
      })
      .addCase(CONFIG_ADD_UTMMEDIUM, (state, action) => {
        state.settings.utmMedium = action.payload;
      })
      .addCase(CONFIG_ADD_MSCLKID, (state, action) => {
        state.settings.msclkid = action.payload;
      })
);
