import msm_api from "../../api/msm-api.json";
import {
  useOnlyMdMediaQuery,
  useTabletAndBelowMediaQuery,
} from "../../components/ComponentUtils/Responsive";
import { useStaticQueryDataChatMutuo } from "../../query/useStaticQueryDataChatMutuo";
import { useStaticQueryDataComeFunziona } from "../../query/useStaticQueryDataComeFunziona";
import { CHAT_MUTUO_TOGGLE } from "../../state/actions/chatmutuo.actions";
import { FETCH_MUTUI_BY_ID } from "../../state/thunk/mutui.thunk";
import { flatMenuListToHierarchical } from "../../utils/helpers";
import ComeFunziona from "../BannerPostNavbarHP/ComeFunziona";
import Image from "../Image";
import Text from "../Text";
import UniversalLink from "../UniversalLink";
import * as serviceBarStyles from "./service-bar.module.scss";
import ChatMutoAvatarMobileSVG from "/static/chat-mutuo-avatar-mobile.svg";
import { useLocation } from "@reach/router";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { graphql, navigate, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import slugify from "slugify";

const accordionVariants = {
  hidden: {
    height: 0,
  },
  visible: {
    height: "100%",
  },
};

function scrollToRicercatore() {
  const element = document.getElementById("mutui");
  if (element) {
    window.scrollTo({
      top: element.getBoundingClientRect().top + window.scrollY - 120,
      behavior: "smooth",
    });
  }
}

const ServiceBar = ({
  isChatMutuoTemplate = false,
  isHpTemplateLightVersion = false,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isTablet = useOnlyMdMediaQuery();
  // console.log('isTablet',isTablet)
  const homeParams =
    useSelector((state) => state.requests.researcher?.HP) || null;
  const [activeKey, setActiveKey] = useState(null);
  const { acfOptionsComeFunziona } = useStaticQueryDataComeFunziona();

  const {
    chatMutuo: {
      logoMobile,
      assistente: { avatarMobile },
    },
  } = useStaticQueryDataChatMutuo();

  const handleShowMobileMenu = () => {
    if (activeKey === 2) {
      setActiveKey(null);
    } else {
      setActiveKey(2);
    }
  };
  const handleShowComeFunziona = () => {
    if (activeKey === 1) {
      setActiveKey(null);
    } else {
      setActiveKey(1);
    }
  };
  const handleCercaMutuo = () => {
    if (location.pathname === "/") {
      dispatch(
        FETCH_MUTUI_BY_ID({
          id: "HP",
          postData: {
            ...(homeParams?.params || {
              ...msm_api.endpoint.Ricerca.default?.HP,
            }),
          },
          endpoint: "Ricerca",
          type: "HP",
        })
      );
      scrollToRicercatore();
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    setActiveKey(null);
  }, [location]);

  const {
    wp: {
      acfOptionsTema: {
        opzioniTema: { siteLogo, menuMobile },
      },
    },
    allWpMenuItem: { serviceBarMenu },
    menu,
  } = useStaticQuery(graphql`
    query serviceBarMenuQuery {
      allWpMenuItem(
        filter: { locations: { eq: SERVICE_BAR_MENU } }

        sort: { order: ASC }
      ) {
        serviceBarMenu: nodes {
          label
          title
          id
          path
          cssClasses
          target
          order
          acfServiceBarMenu {
            nascondiMobile
            icona {
              altText
              height
              width
              title
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
      menu: allWpMenuItem(filter: { locations: { eq: MAIN_MENU } }) {
        nodes {
          label
          title
          id
          path
          cssClasses
          target
          parentId
          # acfHeaderNavigation {
          # 	sectionTitle
          # }
          connectedNode {
            node {
              ... on WpPage {
                id
                template {
                  templateName
                }
              }
            }
          }
          acfMenuNovita {
            fieldGroupName
            menuNovita
          }
          acfEtichettaCorta {
            fieldGroupName
            nuovaEtichetta
            etichettaCorta
          }
        }
      }
      wp {
        acfOptionsTema {
          opzioniTema {
            siteLogo: generali {
              logo {
                altText
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
                height
                width
                title
              }
            }
            menuMobile {
              testoSottoVoceDiMenu
            }
          }
        }
      }
    }
  `);

  const logoImageData = siteLogo?.logo || null;
  const hierarchicalList = flatMenuListToHierarchical(menu.nodes);

  if (isHpTemplateLightVersion) {
    return null;
  }

  const MenuConditional = ({ templateName, label, novitaClass }) => {
    if (templateName === "ChatMutuo") {
      return (
        <>
          <span
            style={{
              color: "#72B043",
            }}
          >
            Chat
          </span>
          <span
            style={{
              color: "#468A4C",
            }}
          >
            Mutuo
          </span>
          <sup
            style={{
              color: "#F37324",
              fontSize: ".5625rem",
              fontWeight: "bold",
            }}
          >
            BETA
          </sup>
        </>
      );
    } else if (
      //checkbox acf
      novitaClass
    ) {
      return (
        <>
          <span
            style={{
              paddingRight: "5px",
            }}
          >
            <img
              src="/megafono.svg"
              alt="megafono icon"
              width={15}
              height={15}
            />
          </span>
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {label}
          </span>
        </>
      );
    } else {
      return label;
    }
  };

  return (
    <>
      <nav className={serviceBarStyles.serviceBar}>
        <ul>
          <li key={"hamburger-menu"}>
            <button
              className={serviceBarStyles.serviceBarMenuButton}
              onClick={() => handleShowMobileMenu()}
            >
              <div>
                <img
                  src={
                    activeKey === 2 ? "/sbm-menu-close.svg" : "/ico-menu.svg"
                  }
                  alt="hamburger menu"
                  height="20"
                  width="25"
                />
              </div>
              {/* MENU */}
            </button>
          </li>
          <li key={"vantaggi-panel"}>
            <button
              className={serviceBarStyles.serviceBarMenuButton}
              onClick={() => handleShowComeFunziona()}
            >
              <div>
                <img
                  src={
                    activeKey === 1
                      ? "/sbm-menu-close.svg"
                      : "/ico-vantaggi.svg"
                  }
                  alt="icona youtube"
                  height="20"
                  width="25"
                />
              </div>
              {/* VANTAGGI */}
            </button>
          </li>
          {
            //serviceBarMenu.slice(0, isMobile ? 0 : 1).map((menuItem, i) => {
            serviceBarMenu.slice(0, !isTablet ? 0 : 1).map((menuItem, i) => {
              const {
                target,
                path,
                label,
                acfServiceBarMenu: { icona, nascondiMobile },
              } = menuItem;
              const hideItem = nascondiMobile
                ? "d-none d-md-inline-block"
                : null;
              return (
                <li key={`${i}-${path}`} className={hideItem}>
                  <UniversalLink to={path} target={target}>
                    <div>
                      <Image data={icona} height="20" width="25" />
                    </div>{" "}
                    a{/* {label.toUpperCase()} */}
                  </UniversalLink>
                </li>
              );
            })
          }
          <li
            className={classNames({
              ["d-none"]: !isChatMutuoTemplate,
              ["d-inline-block"]: isChatMutuoTemplate,
            })}
          >
            <button
              onClick={handleCercaMutuo}
              className={serviceBarStyles.btnNoStyle}
              aria-label="Cerca Mutuo"
            >
              <div className={serviceBarStyles.fixedIcon}>
                <img
                  className={serviceBarStyles.icon}
                  src="/carrello.svg"
                  alt="banca carrello"
                  width={50}
                  height={50}
                />
              </div>
              CERCA MUTUO
            </button>
          </li>
          <li
            className={classNames({
              ["d-none"]: isChatMutuoTemplate,
              ["d-inline-block"]: !isChatMutuoTemplate,
            })}
          >
            <button
              onClick={() => dispatch(CHAT_MUTUO_TOGGLE())}
              className={serviceBarStyles.btnNoStyle}
              aria-label="Chat Mutuo"
            >
              <div className={serviceBarStyles.fixedIcon}>
                <img
                  className={serviceBarStyles.icon}
                  src={
                    avatarMobile?.localFile?.publicURL ??
                    ChatMutoAvatarMobileSVG
                  }
                  alt="chat mutuo avatar"
                  width={35}
                  height={40}
                />
              </div>
              {/* <img
								className={serviceBarStyles.chatMutuoLogo}
								src={logoMobile?.localFile?.publicURL ?? ChatMutoLogoMobileSVG}
								alt="chat mutuo"
							/> */}
            </button>
          </li>
          {serviceBarMenu
            //.slice(isMobile ? 0 : 1, isMobile ? 2 : 5)
            .slice(!isTablet ? 0 : 1, !isTablet ? 2 : 5)
            .map((menuItem, i) => {
              let cssClassesLinkItem = menuItem?.cssClasses.length
                ? [...menuItem?.cssClasses].join(" ")
                : null;
              let {
                target,
                path,
                label,
                acfServiceBarMenu: { icona, nascondiMobile },
              } = menuItem;

              let hideItem = nascondiMobile ? "d-none d-md-inline-block" : null;
              return (
                <li key={`${i}-${path}`} className={hideItem}>
                  <UniversalLink
                    to={path}
                    className={cssClassesLinkItem}
                    target={target}
                  >
                    <div>
                      <Image data={icona} height="20" width="25" />
                    </div>
                    {/* {label.toUpperCase()} */}
                  </UniversalLink>
                </li>
              );
            })}
        </ul>
      </nav>
      <AnimatePresence>
        <motion.section
          key="showComeFunziona"
          className={classNames(serviceBarStyles.menuMobile)}
          initial="hidden"
          animate={activeKey === 1 ? "visible" : "hidden"}
          variants={accordionVariants}
          transition={{ duration: 0.4 }}
          onAnimationComplete={() => {
            if (activeKey === 1) {
              document.body.style.overflow = "hidden";
            } else {
              document.body.style.overflow = "auto";
            }
          }}
        >
          <div
            className={classNames(
              serviceBarStyles.menuMobileWrapper,
              serviceBarStyles.menuMobileWrapperNoPaddingX
            )}
          >
            <div
              role="button"
              aria-label="Chiudi Menu"
              tabIndex={"0"}
              className={serviceBarStyles.menuMobileClose}
              onClick={() => setActiveKey(null)}
              onKeyDown={(e) => e.key === "Enter" && setActiveKey(null)}
            />
            <div
              className={classNames(
                serviceBarStyles.menuMobileTop,
                serviceBarStyles.menuMobileTopNoPaddingY
              )}
            >
              {logoImageData ? (
                <Image data={logoImageData} />
              ) : (
                <h2 className="text-black">MSM</h2>
              )}
            </div>
            <ComeFunziona
              data={acfOptionsComeFunziona}
              show={activeKey === 1}
              disableLoader={true}
              classNamePadding={serviceBarStyles.comeFunziona}
            />
          </div>
        </motion.section>
        <motion.section
          key="showMobileMenu"
          className={serviceBarStyles.menuMobile}
          initial="hidden"
          animate={activeKey === 2 ? "visible" : "hidden"}
          variants={accordionVariants}
          transition={{ duration: 0.4 }}
          onAnimationComplete={() => {
            if (activeKey === 2) {
              document.body.style.overflow = "hidden";
            } else {
              document.body.style.overflow = "auto";
            }
          }}
        >
          <div className={serviceBarStyles.menuMobileWrapper}>
            <div
              role="button"
              aria-label="Chiudi Menu"
              tabIndex={"0"}
              className={serviceBarStyles.menuMobileClose}
              onClick={() => setActiveKey(null)}
              onKeyDown={(e) => e.key === "Enter" && setActiveKey(null)}
            />
            <div className={serviceBarStyles.menuMobileTop}>
              {logoImageData ? (
                <Image data={logoImageData} />
              ) : (
                <h2 className="text-black">MSM</h2>
              )}
            </div>
            <Accordion
              as={"nav"}
              flush
              className={serviceBarStyles.menuMobileMenu}
            >
              {hierarchicalList.map((menuItem, i) => {
                const cssClassesLinkItem = [...menuItem?.cssClasses].join(" ");
                const { target, path, label, children } = menuItem;
                const templateName =
                  menuItem?.connectedNode?.node?.template?.templateName || "";
                const haveChildren = children?.length > 0 ?? false;
                const key = `${i}-${slugify(path)}`;
                const hasMenuNovita = menuItem?.acfMenuNovita?.menuNovita;

                const isActive2 = location.pathname === path;
                return (
                  <Accordion.Item
                    key={key}
                    eventKey={key}
                    className={serviceBarStyles.accordionItem}
                  >
                    {haveChildren ? (
                      <Accordion.Button
                        className={`${serviceBarStyles.accordionButton}`}
                      >
                        <UniversalLink
                          to={path}
                          className={cssClassesLinkItem}
                          target={target}
                        >
                          {label}
                        </UniversalLink>
                      </Accordion.Button>
                    ) : (
                      <div
                        className={`${serviceBarStyles.accordionFullLink} ${serviceBarStyles.accordionButton}`}
                      >
                        <UniversalLink
                          to={path}
                          className={classNames({
                            cssClassesLinkItem,
                            [serviceBarStyles.isActive2]: isActive2,
                          })}
                          target={target}
                        >
                          <MenuConditional
                            templateName={templateName}
                            label={label}
                            novitaClass={hasMenuNovita}
                          />
                          {/* {templateName === "ChatMutuo" ? (
														<>
															<span
																style={{
																	color: "#72B043",
																}}
															>
																Chat
															</span>
															<span
																style={{
																	color: "#468A4C",
																}}
															>
																Mutuo
															</span>
															<sup
																style={{
																	color: "#F37324",
																	fontSize: ".75rem",
																}}
															>
																BETA
															</sup>
														</>
													) : (
														label
													)} */}
                        </UniversalLink>
                      </div>
                    )}
                    {haveChildren && (
                      <Accordion.Body
                        className={serviceBarStyles.accordionBody}
                      >
                        <ul>
                          {children.map((menuItem, i) => {
                            const cssClassesLinkItem = [
                              ...menuItem?.cssClasses,
                            ].join(" ");
                            const { target, path, label } = menuItem;
                            const key = `${i}-${slugify(path)}`;
                            const isActive = location.pathname === path;
                            return (
                              <li key={key}>
                                <UniversalLink
                                  to={path}
                                  className={classNames({
                                    cssClassesLinkItem,
                                    [serviceBarStyles.isActive]: isActive,
                                  })}
                                  target={target}
                                >
                                  {label}
                                </UniversalLink>
                              </li>
                            );
                          })}
                        </ul>
                      </Accordion.Body>
                    )}
                  </Accordion.Item>
                );
              })}
            </Accordion>
            <div className={serviceBarStyles.wrapperTestoSottoVoceDiMenu}>
              <Text
                content={menuMobile?.testoSottoVoceDiMenu}
                className={serviceBarStyles.testoSottoVoceDiMenu}
              />
            </div>
          </div>
        </motion.section>
      </AnimatePresence>
    </>
  );
};

export default ServiceBar;
