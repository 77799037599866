import { MSMRequest } from "../../../../api/msm";
import Text from "../../../Text";
import * as ConsulenzaGratuitaStyles from "./consulenza-gratuita.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import parse from "html-react-parser";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Controller, useForm } from "react-hook-form";
import { IMaskInput } from "react-imask";
import * as yup from "yup";
import "yup-phone-lite";

function ConsulenzaGratuita({ labelCta, consenso, setSettingsModal }) {
  const schemaConsulenzaGratuita = yup.object().shape(
    {
      Nome: yup
        .string()
        .min(2, 'Il campo "Nome" deve contenere almeno 2 caratteri')
        .trim()
        .required('Il campo "Nome" è obbligatorio')
        .matches(/^[aA-zZ\s]+$/, 'Il campo "Nome" può contenere solo lettere'),
      Cognome: yup
        .string()
        .min(2, 'Il campo "Cognome" deve contenere almeno 2 caratteri')
        .trim()
        .required('Il campo "Cognome" è obbligatorio')
        .matches(
          /^[aA-zZ\s]+$/,
          'Il campo "Cognome" può contenere solo lettere'
        ),
      Telefono01: yup
        .string()
        .phone("IT", 'Il campo "1° Telefono" deve essere un numero valido')
        .required('Il campo "1° Telefono" è obbligatorio'),
      Telefono02: yup.string().when("Telefono02", {
        is: (value) => value?.length > 0,
        then: yup
          .string()
          .phone("IT", 'Il campo "2° Telefono" deve essere un numero valido'),
        otherwise: yup.string().nullable().optional(),
      }),
      Email: yup
        .string()
        .email('Il campo "Email" deve essere un indirizzo valido')
        .required('Il campo "Email" è obbligatorio'),
      Note: yup.string().nullable().optional(),
      privacy: yup
        .boolean()
        .oneOf(
          [true],
          "Devi accettare la privacy policy per inviare il modulo"
        ),
    },
    [["Telefono02", "Telefono02"]]
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schemaConsulenzaGratuita),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      Nome: "",
      Cognome: "",
      Email: "",
      Telefono01: "",
      Telefono02: "",
      Note: "",
      CodiceOriginator: 1,
    },
  });

  const watchs = watch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (data) => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("formConsulenzaGratuita");
    const response = await MSMRequest("FattiChiamare", {
      ...data,
      ...{ gRecaptchaResponse: token },
    });
    if (response.success) {
      const BodyContent = (
        <Text content="Il messaggio è stato inviato con successo. A breve sarai contattato da un nostro consulente." />
      );
      setSettingsModal({
        title: "Grazie",
        content: BodyContent,
      });
      reset();
    } else {
      const BodyContent = (
        <Text content="Si è verificato un errore. Riprova più tardi." />
      );
      setSettingsModal({
        title: "Attenzione",
        content: BodyContent,
      });
    }
  };

  const onError = (errors, e) => {
    const BodyContent = (
      <>
        {Object.keys(errors).map((key, index) => {
          return <p key={index}>{errors[key].message}</p>;
        })}
      </>
    );
    setSettingsModal({
      title: "Attenzione",
      content: BodyContent,
    });
  };

  return (
    <Form className={ConsulenzaGratuitaStyles.form} autoComplete="off">
      <Row className={ConsulenzaGratuitaStyles.formRow}>
        <Col md={6}>
          <div
            className={classNames(ConsulenzaGratuitaStyles.field, {
              [ConsulenzaGratuitaStyles.fieldError]: errors.Nome,
              [ConsulenzaGratuitaStyles.fieldValid]:
                !errors.Nome && watchs.Nome?.length > 0,
            })}
          >
            <label
              htmlFor="Nome"
              className={classNames({
                [ConsulenzaGratuitaStyles.filled]: watchs.Nome?.length > 0,
              })}
            >
              Nome*
            </label>
            <input type="text" {...register("Nome")} />
          </div>
        </Col>
        <Col md={6}>
          <div
            className={classNames(ConsulenzaGratuitaStyles.field, {
              [ConsulenzaGratuitaStyles.fieldError]: errors.Cognome,
              [ConsulenzaGratuitaStyles.fieldValid]:
                !errors.Cognome && watchs.Cognome?.length > 0,
            })}
          >
            <label
              htmlFor="Cognome"
              className={classNames({
                [ConsulenzaGratuitaStyles.filled]: watchs.Cognome?.length > 0,
              })}
            >
              Cognome*
            </label>
            <input type="text" {...register("Cognome")} />
          </div>
        </Col>
        <Col md={6}>
          <div
            className={classNames(ConsulenzaGratuitaStyles.field, {
              [ConsulenzaGratuitaStyles.fieldError]: errors.Telefono01,
              [ConsulenzaGratuitaStyles.fieldValid]:
                !errors.Telefono01 && watchs.Telefono01?.length > 0,
            })}
          >
            <label
              htmlFor="Telefono01"
              className={classNames({
                [ConsulenzaGratuitaStyles.filled]:
                  watchs.Telefono01?.length > 0,
              })}
            >
              1° Telefono*
            </label>
            <Controller
              name="Telefono01"
              control={control}
              valueAsNumber={true}
              render={({ field }) => (
                <IMaskInput
                  mask={Number}
                  unmask={true}
                  onAccept={(value, mask) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          </div>
        </Col>
        <Col md={6}>
          <div
            className={classNames(ConsulenzaGratuitaStyles.field, {
              [ConsulenzaGratuitaStyles.fieldError]: errors.Telefono02,
              [ConsulenzaGratuitaStyles.fieldValid]:
                !errors.Telefono02 && watchs.Telefono02?.length > 0,
            })}
          >
            <label
              htmlFor="Telefono02"
              className={classNames({
                [ConsulenzaGratuitaStyles.filled]:
                  watchs.Telefono02?.length > 0,
              })}
            >
              2° Telefono
            </label>
            <Controller
              name="Telefono02"
              control={control}
              valueAsNumber={true}
              render={({ field }) => (
                <IMaskInput
                  mask={Number}
                  unmask={true}
                  onAccept={(value, mask) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          </div>
        </Col>
        <Col md={6}>
          <div
            className={classNames(ConsulenzaGratuitaStyles.field, {
              [ConsulenzaGratuitaStyles.fieldError]: errors.Email,
              [ConsulenzaGratuitaStyles.fieldValid]:
                !errors.Email && watchs.Email?.length > 0,
            })}
          >
            <label
              htmlFor="Email"
              className={classNames({
                [ConsulenzaGratuitaStyles.filled]: watchs.Email?.length > 0,
              })}
            >
              E-mail*
            </label>
            <input type="email" {...register("Email")} />
          </div>
        </Col>
        <Col md={12}>
          <div className={ConsulenzaGratuitaStyles.field}>
            <textarea {...register("Note")} placeholder="Messaggio" />
          </div>
        </Col>
        <Col md={12}>
          <div
            className={classNames(ConsulenzaGratuitaStyles.fieldCheckbox, {
              [ConsulenzaGratuitaStyles.fieldError]: errors?.privacy,
            })}
          >
            <input type="checkbox" {...register("privacy")} />
            <label htmlFor="privacy">
              {parse(consenso.label, {
                replace: (domNode) => {
                  if (
                    domNode.attribs &&
                    domNode.attribs["data-config"] &&
                    domNode.attribs["data-config"].length > 0
                  ) {
                    const dataConfigParsed = JSON.parse(
                      domNode.attribs["data-config"]
                    );
                    switch (dataConfigParsed.type) {
                      case "open-popup":
                        return (
                          <button
                            className={ConsulenzaGratuitaStyles.trigger}
                            onClick={(e) => {
                              e.preventDefault();
                              const BodyContent = (
                                <>
                                  <Text
                                    content={consenso.popup.testo}
                                    removeP={false}
                                  />
                                  bbb
                                </>
                              );
                              setSettingsModal({
                                title: consenso.popup.titolo,
                                content: BodyContent,
                              });
                            }}
                          >
                            {dataConfigParsed.label}
                          </button>
                        );
                      default:
                        break;
                    }
                  }
                },
              })}
            </label>
          </div>
        </Col>
        <Col md={12}>
          <button
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
            className={ConsulenzaGratuitaStyles.button}
            disabled={isSubmitting}
          >
            {labelCta}
          </button>
        </Col>
      </Row>
    </Form>
  );
}

export default ConsulenzaGratuita;
