import { createAction } from "@reduxjs/toolkit";

export const SET_USER_SELECTED_MUTUO = createAction("user/mutuo/selected/set");
export const SET_USER_MUTUO_PARAM = createAction("user/mutuo/param/set");
export const SET_USER_PREVENTIVO_PARAM = createAction(
  "user/preventivo/param/set"
);
export const ADD_USER_MUTUO_HISTORY = createAction("user/mutuo/history/add");
export const SORT_MUTUI = createAction("user/mutui/sort");

export const ADD_USER_MUTUO_COMPARE = createAction("user/mutuo/compare/add");
export const REMOVE_USER_MUTUO_COMPARE = createAction(
  "user/mutuo/compare/remove"
);
export const CLEAR_USER_MUTUO_COMPARE = createAction(
  "user/mutuo/compare/clear"
);

export const CHECK_SET_USER_SELECTED_MUTUO = createAction(
  "user/mutuo/check/selected/set"
);
