import { MSMRequest } from "../../api/msm";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const FETCH_HISTORY = createAsyncThunk(
  "requests/mutuo/history/fetch",
  async (data, thunkAPI) => {
    try {
      const params = {
        CodiceMutuo: data[0],
        ImportoMutuo: data[1][0],
        ValoreImmobile: data[1][1],
        Durata: data[1][2],
        TipoTasso: data[1][3],
        Finalita: data[1][4],
        ProvinciaImmobile: data[1][5],
        ProvinciaDomicilio: data[1][6],
        EtaRichiedente: data[1][7],
        RedditoMensile: data[1][8],
        ClasseEnergetica: data[1][9],
      };
      const response = await MSMRequest("RicercaMutuo", params);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
