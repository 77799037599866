import { MSMChatRequest } from "../../api/msm";
import msm_api from "../../api/msm-api.json";
import { CHAT_MUTUO_ADD_MESSAGES } from "../actions/chatmutuo.actions";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Buffer } from "buffer/index.js";
import uniqueId from "lodash/uniqueId";

const getStream = async (data) => {
  const response = await fetch(
    `${process.env.GATSBY_MSM_API_CHAT_ENDPOINT}${msm_api.endpoint["ChatMutuo/ChatMessage"].url}`,
    {
      method: msm_api.endpoint["ChatMutuo/ChatMessage"].method,
      headers: {
        ...msm_api.headers,
        Authorization:
          "Basic " +
          Buffer.from(
            `${process.env.API_AUTH_USERNAME}:${process.env.API_AUTH_PASSWORD}`,
            "binary"
          ).toString("base64"),
      },
      body: JSON.stringify(data),
    }
  );
  if (!response.ok) throw new Error(response.statusText);
  return response.body;
};

async function* decodeStreamToJson(data) {
  if (!data) return;
  const reader = data.getReader();
  const decoder = new TextDecoder();
  while (true) {
    try {
      const { done, value } = await reader.read();
      if (done) break;
      let decoded = decoder
        .decode(value)
        .replace(/^\[/, "")
        .replace(/\]$/, "")
        .replace(/^\,/g, "")
        .replace(/\,$/g, "");
      decoded = decoded.split('},{"msg').map((item, index) => {
        let data = item;
        if (index > 0) {
          data = '{"msg' + data;
        }
        if (item.endsWith("}") && !item.endsWith("}}")) {
          data = data + "}";
        }
        return data;
      });
      for (const item of decoded) {
        // yield JSON.parse(item)
        if (item)
          try {
            yield JSON.parse(item);
            // console.log("THIS IS ITEM", item)
          } catch (error) {
            // console.error("Error al analizar JSON:", item, error);
            // return data + messageError //UNCOMMENTANDO QUESTO PIU UN 4G SLOW SI RIESCE A VEDERE IL MESSAGGIO BUOTO
          }
      }
    } catch (error) {
      break;
    }
  }
}

export const CHAT_MUTUO_FETCH_MESSAGE = createAsyncThunk(
  "chatmutuo/chat/message/fetch/stream",
  async (args, thunkAPI) => {
    const { data } = args;
    const id = uniqueId("message-assistant-");
    thunkAPI.dispatch(
      CHAT_MUTUO_ADD_MESSAGES({
        Sender: "assistant",
        id,
        loading: true,
        error: false,
      })
    );
    const stream = await getStream({
      ...data,
      TonoConversazione:
        thunkAPI.getState().chatmutuo.messages.conversationType ||
        "PROFESSIONALE",
      Uid: thunkAPI.getState().chatmutuo.messages.uid || "",
      clientId: thunkAPI.getState().chatmutuo.messages.clientId || "",
    });
    if (!stream) throw new Error();
    let incomingUID = null;
    let fallbackMessage = "Ops! Qualcosa è andato storto. Riprova più tardi.";
    let completedMessage = fallbackMessage;
    let nRisposta = 0;
    let mRisposte = 0;
    const decodedStream = decodeStreamToJson(stream);

    for await (const message of decodedStream) {
      if (message?.success && message.data.message.stato === "PARZIALE") {
        thunkAPI.dispatch(
          CHAT_MUTUO_ADD_MESSAGES({
            Messaggio: message.data.message.messaggio,
            Sender: message.data.message.sender,
            id,
            loading: true, //SE DIVENTA TRUE, FUNZIONANO LE PALLE DI CARICAMENTO
            // loading: false, //SE DIVENTA FALSE, NON FUNZIONANO LE PALLE DI CARICAMENTO
            error: false,
          })
        );
      } else if (
        message?.success &&
        message.data.message.stato === "COMPLETO"
      ) {
        // thunkAPI.dispatch(
        // 	CHAT_MUTUO_ADD_MESSAGES({
        // 		loading: false,
        // 	})
        // )
        //  console.log("this is message data")
        //  console.log(message.data)
        // console.log("this is maxRisposte:")
        // console.log(message.data.maxRisposte)
        // console.log("this is numeroRisposta")
        // console.log(message.data.numeroRisposta)
        nRisposta = message?.data?.numeroRisposta;
        mRisposte = message?.data?.maxRisposte;

        incomingUID = message.data.uid;
        completedMessage = message.data.message.messaggio;
        if (message.data.message?.links?.length > 0) {
          thunkAPI.dispatch(
            CHAT_MUTUO_ADD_MESSAGES({
              Data: {
                titolo: message.data.message?.titolo || "",
                elementi: message.data.message?.links || [],
              },
              Sender: "cms",
              // loading: false, //SE SI FA UNCOMMENT, FUNZIONANO LE PALLE DI CARICAMENTO

              id: uniqueId("message-cms-"),
            })
          );
        }
        break;
      } else {
        throw new Error("OH no");
      }
    }
    return {
      success: true,
      incomingUID,
      completedMessage,
      id,
      nRisposta,
      mRisposte,
    };
  }
);

export const CHAT_MUTUO_FETCH_HISTORY = createAsyncThunk(
  "chatmutuo/chat/messages/history/fetch",
  async (args, thunkAPI) => {
    try {
      const response = await MSMChatRequest("ChatMutuo/RecuperaChat", args);
      // console.log("this is Fetch History response:")
      // console.log(response)
      //const response = await MSMRequest("ChatMutuo/RecuperaChat", args)
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
