import { removePTag } from "../../utils/helpers";
import classNames from "classnames";
import parse from "html-react-parser";
import React from "react";

function Text({
  content,
  removeP = true,
  className = "",
  rowContent = false,
  id = null,
  children,
  position = "",
}) {
  if (children) {
    return <div className={classNames("editor", className)}>{children}</div>;
  }

  if (!content || content.length === 0) {
    return null;
  }

  const text = removeP ? removePTag(content) : content;

  if (rowContent) {
    return parse(text);
  }

  if (id) {
    return (
      <div id={id} className={classNames("editor", className)}>
        {parse(text)}
      </div>
    );
  }

  return <div className={classNames("editor", className)}>{parse(text)}</div>;
}

export default Text;
