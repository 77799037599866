import { SHOW_MODAL_COMPARE_MUTUI } from "../actions/ui.actions";
import { ADD_USER_MUTUO_COMPARE } from "../actions/user.actions";
import { createListenerMiddleware } from "@reduxjs/toolkit";

const listenerMiddlewareComparator = createListenerMiddleware();

listenerMiddlewareComparator.startListening({
  actionCreator: ADD_USER_MUTUO_COMPARE,
  effect: async (_, listenerApi) => {
    const comparator = listenerApi.getState().user.comparator;
    if (comparator && comparator?.length === 2) {
      listenerApi.dispatch(SHOW_MODAL_COMPARE_MUTUI());
    }
  },
});

export const middlewareComparator = listenerMiddlewareComparator.middleware;
